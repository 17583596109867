<template>
  <div id="videoplayer_container">
    <template v-if="!emptyPlaylist">
      <!-- La playlist es correcta y tiene videos -->
      <VideoDouob v-for="item in playlist" :key="item.id" :ref="item.id" :src="item.video.url"
      @ended="onVideoPlayerEnded" :muted="true" />
    </template>
  </div>

</template>

<script>
import VideoDouob from '../VideoDouob.vue';

export default {
  components: {
    VideoDouob,
  },
  data() {
    return {
      selected: {},
    }
  },
  props: {
    playlist: {
      type: Array,
      required: true,
      played: false,
    },
  },
  methods: {
    Pause(){
      this.videoplayer.pause()
    },
    ResumePlayback(){
      this.videoplayer.play()
    },
    onVideoPlayerEnded() {
      // Next video
      // const arrToLook = this.standbyMode ? this.options.standByVideos : this.options.videos; // con esto la playlist sigue al proximo video
      let currElementIndex = this.playlist.indexOf(this.selected);
      const nextIndex = (currElementIndex + 1) % this.playlist.length;
      this.selected = null;
      this.selected = this.playlist[nextIndex]
      this.$emit('videoEnded')
    },
  },
  computed: {
    videoplayer() {
      return this.$refs[this.selected.id][0]
    },
    emptyPlaylist() {
      if(this.playlist.length == 0){
        return true;
      }
      if(this.playlist.some(x => !x.video)){
        return true;
      }
      return false;
    },
  },
  watch: {
    // volume(vol) {
    //   // if (this.player) {
    //   //   this.player.volume = vol / 100;
    //   // }
    // }
    selected(selected, prevVideo) {
      if(this.emptyPlaylist) {
        return null;
      }
      console.log("Selected changed", selected.video.filename)
      // console.log("***", prevVideo.id)
      if (prevVideo.id) {
        this.$refs[prevVideo.id][0].stop()
      }
      this.videoplayer.play()
    }
  },
  mounted() {
    if(this.emptyPlaylist) {
      console.log("No videos in playlist")
      return
    }
    this.selected = this.playlist[0]
    this.$refs
  },
}
</script>